<template>
	<div class="modal-mask">
		<div class="projects-modal-form" id="projects-modal-form">
			<i class="fas fa-times close-modal" @click="$emit('close')"></i>
			<h2>{{ title }} Project</h2>
			<form action="">
				<div class="input-field file">
					<label for="file-upload" class="custom-file-upload">
						<div class="upload">
							<div>
								<i class="fa fa-cloud-upload-alt upload-icon"></i>
								{{ fileName }}
								<input id="file-upload" type="file" @change="onFileChange" required/>
							</div>
							<img v-show="imageURL != ''" :src="imageURL" class="preview-image" alt="projectImage">
						</div>
					</label>
					<p v-if="error != ''" class="image-error">{{ error }}</p>
				</div>
				<div class="text-inputs">
					<div class="input-field">
						<input type="text" name="project-name" id="project-name" v-model="project.projectName" placeholder="Project Name">
					</div>
					<div class="input-field">
						<textarea name="project-description" id="project-description" placeholder="Project Description" cols="30" rows="7" v-model="project.projectDescription"></textarea>
					</div>
				</div>
			</form>
			<button type="submit" @click.prevent="$emit('addUpdate', imageToUpload, project.projectName, project.projectDescription)" class="btn btn-primary">
				{{ title == 'Edit' ? 'Update' : 'Add' }}
			</button>
		</div>
	</div>
</template>

<script>
import firebase from 'firebase/compat';

export default {
	name: 'AddEditProjects',
	props: ['error', 'item', 'title'],
	data() {
		return {
			fileName: 'Upload Image',
			imageToUpload: {},
			imageURL: '',
			project: {}
		}
	},
	methods: {
		onFileChange(event) {
			this.imageToUpload = event.target.files[0];
			this.imageURL = URL.createObjectURL(this.imageToUpload),
			this.fileName = this.imageToUpload.name;
			this.$emit('imageChange');
		},
		async loadData() {
			this.project = this.item;
			if(this.title == 'Edit') {
				const ref = firebase.storage().ref('projects/' + this.project.imageName);
				const url = await ref.getDownloadURL()
				this.imageURL = url;
				this.fileName = this.project.imageName;
			}
		},
	},
	mounted() {
		this.loadData();
	}
}
</script>

<style lang="scss" scoped>
.projects-modal-form {
	position: absolute;
	left: 50%;
	top: 50%;
	max-height: 70%;
	width: 70%;
	z-index: 99;
	color: white;
	padding: 3rem;
	border-radius: 1rem;
	transform: translate(-50%, -50%);
	background-image: url(../../assets/bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: overlay;
}
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}
.close-modal {
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 15px;
	font-size: 2rem;
	transition: all 100ms ease-in-out;
	&:hover {
		transform: scale(1.1);
	}
}

form {
	display: flex;
	grid-gap: 2rem;
}

h2 {
	margin: 0;
}

img {
	margin-top: 0.5rem
}

.input-field {
	margin: 0.75rem 0;
}

input[type=text], input[type=url], input[type=month], textarea {
	border-radius: 8px;
	padding: 8px;
	vertical-align: middle;
	border: none;
	color: black;
	background: #f1f1f1;
	opacity: 0.9;
	width: 90%;
}
input[type=text]:focus, input[type=url]:focus, input[type=month]:focus, textarea:focus {
	background-color: #ddd;
	outline: none;
}
input[type=file] {
	display: none;
}
.custom-file-upload {
	display: flex;
	flex-direction: column;
	padding: 10px 12px;
	cursor: pointer;
	border: 1px solid black;
	width: max-content;
	margin: auto;
	background-color: black;
	color: white;
	border-radius: 0.3rem;
}

.custom-file-upload:hover {
	background-color: darkslategrey;
	color: black;
	border: 1px solid darkslategrey;
}
.file {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 50%;
}
.text-inputs {
	width: 50%;
}
.upload-icon {
	margin-right: 10px;
}

.preview-image {
	max-width: 14rem;
	max-height: 18rem;
}

.image-error {
	color: red;
	margin-top: 1rem;
	margin-bottom: 0;
	background-color: black;
	font-weight: 600;
	padding: 0.3rem 0.6rem;
	border-radius: 0.3rem;
}

@media screen and (max-width: 730px) {
	form {
		flex-direction: column;
		align-items: center;
	}
	.text-inputs {
		width: 100%;
	}
	.file {
		width: 100%;
	}
	.preview-image {
		max-width: 30vw;
	}
}
</style>