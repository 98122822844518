<template>
	<div class="edit-projects">
		<div class="action-buttons">
			<button @click="showModal = true" class="btn btn-primary">Add New Project</button>
			<button @click="updateProjects" class="btn btn-success">Save instance</button>
		</div>
		<AddEditProjects v-if="showModal"
			@close="clearModalData"
			@addUpdate="updateProjectsLocal"
			@imageChange="error=''"
			:error="error"
			:item="item"
			:title="title"></AddEditProjects>
		<div class="projects-container">
			<div v-for="(item, index) in editableProjects" :key="index" :style="{backgroundImage: 'url('+imageURLs[index]+')'}" class="project-image-card">
				<div class="details">
					<div class="action-icons">
						<i @click="editProject(item, index, 'Edit')" class="fas fa-pen-alt edit-project"></i>
						<i @click="deleteProject(index, item.imageName, item.projectName)" class="fas fa-trash-alt delete-project"></i>
					</div>
					<p class="project-name">{{ item.projectName }}</p>
					<p class="project-description">{{ item.projectDescription }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import firebase from 'firebase/compat';
// import storage from 'firebase/storage'
import AddEditProjects from './AddEditProjects.vue';

export default {
	name: 'EditProjects',
	components: {
		AddEditProjects
	},
	data() {
		return {
			showModal: false,
			title: 'Add New',
			item: {
				imageName: '',
				projectName: '',
				projectDescription: ''
			},
			editableProjects: this.$store.state.projects,
			imageURLs: [],
			index: 0,
			error: ''
		}
	},
	methods: {
		...mapActions({
			saveProjects: 'updateProjects'
		}),
		updateProjects() {
			this.saveProjects(this.editableProjects);
			this.$emit('notification', 'Changes saved locally!');
		},
		clearModalData() {
			this.showModal = false;
			this.title = 'Add New';
			this.error = '';
			this.item = {
				imageName: '',
				projectName: '',
				projectDescription: ''
			};
		},
		editProject(item, index, title) {
			this.index = index;
			this.title = title;
			this.item = item;
			this.showModal = true;
		},
		deleteProject(index, imageName, projectName) {
			this.$emit('deleteProject', imageName);
			this.editableProjects.splice(index, 1);
			this.$emit('notification', '"' + projectName + '" deleted locally!', 'delete');
		},
		async updateProjectsLocal(image, name, description) {
			if (image.name == undefined) {
				console.log('no image change')
			} else {
				let a = this.editableProjects.filter(item => {
					return item.imageName == image.name;
				});
				if (a.length != 0) {
					this.error = 'An image with the same name already exist.';
					console.log('image found')
					return;
				}
			}
			if (this.title == 'Edit') { // Edit existing entry
				let a = this.editableProjects[this.index];
				a.projectName = name;
				a.projectDescription = description;
				this.$emit('notification', 'Projects updated!');
				if (a.imageName != image.name && image.name != undefined) {
					// delete existing image
					const deleteRef = firebase.storage().ref('projects/'+ a.imageName);
					deleteRef.delete();

					// upload new image
					await firebase.storage().ref('projects/' + image.name).put(image);
					a.imageName = image.name;

					// change image name in realtime database
					const projectsRef = firebase.database().ref('portfolio/projects/' + this.index);
					projectsRef.update({
						'imageName': image.name
					});

					this.$emit('notification', '"' + image.name + '" updated in firebase!', 'firebase');
				}
			} else { // Add new entry
				if(image.name == undefined) {
					this.error = 'Image is required';
					return;
				}
				await firebase.storage().ref('projects/' + image.name).put(image)
				.then(() => {
					this.editableProjects.push({
						imageName: image.name,
						projectName: name,
						projectDescription: description
					});
				}).catch(err => console.log(err));
				this.$emit('notification', '"' + image.name + '" uploaded to firebase!', 'firebase');
			}
			this.loadData();
			this.showModal = false;
			this.title = 'Add New';
			this.error = '';
			this.item = {
				imageName: '',
				projectName: '',
				projectDescription: ''
			};
		},
		async getImage(imageName) {
			const ref = firebase.storage().ref('projects/' + imageName);
			const url = await ref.getDownloadURL()
			return url;
		},
		loadData() {
			const promiseArray = this.editableProjects.map(item => this.getImage(item.imageName));
			Promise.all(promiseArray)
			.then((valueArray) => {
				this.imageURLs = valueArray;
			})
		}
	},
	mounted() {
		this.loadData();
	}
}
</script>

<style lang="scss" scoped>
.edit-projects {
	padding: 5rem 0;
}
.action-buttons {
	display: flex;
	flex-wrap: wrap;
	padding: 0 10vw;
	grid-gap: 1rem;
	margin-bottom: 1rem;
	align-items: center;
}

.projects-container {
	padding: 0 10vw;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 2rem;
}
.project-image-card {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	max-width: 25rem;
	height: 20rem;
	position: relative;
	border-radius: 1rem;
	&:hover {
		.details {
			animation: slide-up 300ms ease-in-out forwards;
		}
		@keyframes slide-up {
			100% {
				opacity: 1;
				visibility: visible;
				transform: translate(0);
			}
		}
	}
}
.details {
	position: absolute;
	color: white;
	text-align: left;
	font-weight: 500;
	padding: 1rem;
	visibility: hidden;
	transform: translate(0, 40%);
	border-radius: 1rem;
	background-color: rgba(55, 53, 196, 0.86);
	overflow: overlay;
	max-height: 20rem;
	width: 100%;
	p {
		margin: 0;
	}
	.project-name {
		font-size: 1.7rem;
		font-weight: 600;
		margin-right: 6rem;
	}
	.project-description {
		white-space: pre-wrap;
	}
	&:not(:hover) {
		animation: slide-out 300ms ease-in-out forwards;	
		@keyframes slide-out {
			from {
				opacity: 1;
				visibility: visible;
				transform: translate(0);
			}
			to {
				opacity: 0;
				visibility: hidden;
				transform: translate(0, 40%);
			}
		}
	}
}

.action-icons {
	position: absolute;
	right: 25px;
	i {
		padding: 0.5rem;
		margin: 0 0.2rem;
		border-radius: 50%; 
		filter: sepia(1);
		transition: all 150ms ease-in-out;
		cursor: pointer;
	}
	i:hover {
		transform: scale(1.1);
	}
}
.edit-exp {
	background-color: #f9e73c;
}
.delete-exp {
	color: burlywood;
	background-color: #a90909;
}

@media screen and (max-width: 1400px) {
	.projects-container {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media screen and (max-width: 1366px) {
	.projects-container {
		grid-template-columns: repeat(3, 1fr);
	}
}
@media screen and (max-width: 1020px) {
	.projects-container {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media screen and (max-width: 720px) {
	.projects-container {
		grid-template-columns: repeat(1, 1fr);
	}
	.project-image-card {
		max-width: initial;
	}
}
</style>